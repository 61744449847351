import React, { useRef, useState } from 'react';

const VirtualKeyboard: React.FC<{
  onKeyPress: (key: string) => void;
  onClose: () => void;
  hideMinus?: boolean;
  modeDecimal?: boolean;
}> = ({ onKeyPress, onClose, hideMinus, modeDecimal = false }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const dragItem = useRef<HTMLDivElement | null>(null);
  const dragStart = useRef<{ x: number; y: number }>();
  const startDrag = (x: number, y: number) => {
    // setIsDragging(true);
    // dragStart.current = {
    //   x: x - position.x,
    //   y: y - position.y,
    // };
  };

  const onDrag = (x: number, y: number) => {
    // if (isDragging && dragStart.current) {
    //   setPosition({
    //     x: x - dragStart.current.x,
    //     y: y - dragStart.current.y,
    //   });
    // }
  };

  const endDrag = () => {
    // setIsDragging(false);
  };

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // startDrag(e.clientX, e.clientY);
    // dragItem.current = e.currentTarget;
  };

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    // const touch = e.touches[0];
    // startDrag(touch.clientX, touch.clientY);
    // dragItem.current = e.currentTarget;
  };

  const onMouseMove = (e: MouseEvent) => {
    // onDrag(e.clientX, e.clientY);
  };

  const onTouchMove = (e: TouchEvent) => {
    // const touch = e.touches[0];
    // onDrag(touch.clientX, touch.clientY);
  };

  const onMouseUp = () => {
    // endDrag();
  };

  const onTouchEnd = () => {
    // endDrag();
  };

  React.useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
      window.addEventListener('touchmove', onTouchMove, { passive: false });
      window.addEventListener('touchend', onTouchEnd);
    } else {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('touchend', onTouchEnd);
    }

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('touchend', onTouchEnd);
    };
  }, [isDragging]);

  const keys = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '0',
    modeDecimal ? ',': null,
    modeDecimal ? '.': null,
    hideMinus ? null : '-',
    '⌫',
    'Закрыть',
  ].filter((key) => key !== null);

  return (
    <div
      ref={dragItem}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      style={{
        position: 'absolute',
        left: `${position.x}px`,
        top: `${position.y}px`,
        cursor: isDragging ? 'grabbing' : 'grab',
        zIndex: 1000,
        width: '700px',
        height: '120px',
      }}
      className="sticky bottom-10 grid grid-cols-7 gap-2 p-2 bg-gray-200 border-t border-gray-300 rounded-[8px]"
    >
      {keys.map((key) => (
        <button
          key={key}
          onClick={() => {
            if (key === 'Закрыть') {
              onClose();
            } else if (key === '-') {
              onKeyPress('-');
            } else {
              onKeyPress(key === '⌫' ? 'delete' : (key as any));
            }
          }}
          className="p-2 w-full h-full flex justify-center items-center bg-white rounded-md shadow text-lg font-medium"
          style={{
            minWidth: '20px',
            minHeight: '48px',
          }}
        >
          {key}
        </button>
      ))}
    </div>
  );
};

export default VirtualKeyboard;
