import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TaskDetailsCharacteristics from './TaskDetailsCharacteristics';
import { requestGetFetch, requestPost } from '../../api/request';
import { DOWNTIME, MATERIALS, TASKS, USERS } from '../../api/url';
import ModalSaveResult from './ModalSaveResult';
import ModalStopPurpose from './ModalStopPurpose/ModalStopPurpose';
import { useAuth } from '../../hooks/useAuth';
import { toast } from 'react-toastify';

import {
  formatTime,
  getActiveMaterialFromCache,
  parseAndBoldText,
  SafeHtmlComponent,
  timestampToDateTime,
  timestrToSec,
} from '../../utils/functions';
import PauseIcon from '../../icons/PauseIcon';
import PlayIcon from '../../icons/PlayIcon';
import NewRollModal, { RollSetupParams } from './NewRollModal';
import DefectWindow from './DefectWindow';
import Modal from '../../components/Modal';
import Button from '../../components/buttons/Button';
import { ServerResponse, Task } from '../../types/models/task';

type MaterialInfo = {name: string | null, thickness: number | null}

export default function TaskDetailsOnce() {
  const {
    accessToken,
    refreshToken,
    userId,
    controlPointId,
    channelWork,
    typeRoute,
    setTypeRoute,
  }: any = useAuth();
  const navigate = useNavigate();

  /**
   * 4 Основные параметры которые получаем с адресной стороки
   */
  const { taskId } = useParams();
  const [searchParams] = useSearchParams();

  //Если мы находимся в задаче на докат
  const parentTaskId = searchParams.get('parentTaskId');
  //Если мы находимся в задаче на производство гладкого листа
  const isFlatSheetProduction = searchParams.get('flatSheet');

  //Если вернулись из доката
  const returnFromChild = searchParams.get('returnFromChild');

  //Состояние загрузчик
  const [loader, setLoader] = useState(false);

  //Список данных добавляются в data
  const [data, setData] = useState<any[]>([]);

  // Выбранная дата
  const [currentTask, setCurrentTaskData] = useState<any>();
  const [pauseTime, setPauseTimer] = useState(false);

  const [activeMenu, setActiveMenu] = useState('сharacteristics');

  const [hourTime, setHourTime] = useState<number>(0);
  const [secondTime, setSecondTime] = useState<number>(0);
  const [minuteTime, setMinuteTime] = useState<number>(0);

  const [timerStart, timerStop] = useState<boolean>(false);

  const selectedRoomRefSecond: any = useRef(0);
  const selectedRoomRefHour: any = useRef(0);
  const selectedRoomRefMinute: any = useRef(0);
  const intervalIDRef = React.useRef<any>(null);

  //Modal form Сохранить текущий результат
  const [modalSaveResultShow, setModalSaveResultShow] = useState(false);
  const [workResults, setWorkResults] = useState({
    materialOperationMatrixId: null,
    quantityDone: null,
  });

  //Модальное окно Причина остановки
  const [modalStopPurpose, setModalPurpose] = useState(false);

  const [user, setUser] = useState<any>();

  const [sumMaxTime, setSumMaxTime] = useState<any>();
  const [sumMinTime, setSumMinTime] = useState<any>();

  const [downTimeDate, setDownTimeDate] = useState<any>(null);

  const [continueTimerByStart, setContinueTimerByStart] = useState(false);

  const [isDefectModalOpen, setIsDefectModalOpen] = useState(false);

  const [isTaskFinishConfirmModalOpen, setIsTaskFinishConfirmModalOpen] =
    useState(false);

  const [currentMaterialName, setCurrentMaterialName] = useState<string | null>(null);
  const [currentMaterialThickness, setCurrentMaterialThickness] = useState<number | null>(null);
  const [itemThickness, setItemThickness] = useState<number | null>(null);

  const [isTaskFirstStart, setIsTaskFirstStart] = useState(true);

  const [isThicknessCheckModalOpen, setIsThicknessCheckModalOpen] =
    useState(false);

  const [currentTaskMaxQuantity, setCurrentTaskMaxQuantity] = useState<any>();

  const getCurrentMaterialId = (): string => {
    const activeMaterialsForControlPoints = JSON.parse(
      localStorage.getItem('activeMaterialsForControlPoints') || ''
    );
    return activeMaterialsForControlPoints
      ? activeMaterialsForControlPoints[controlPointId]?.activeMaterialId
      : null;
  };

  const currentMaterialId = getCurrentMaterialId();

  const hasMirror = getActiveMaterialFromCache(controlPointId, 'hasMirror');

  const reloadTaskInfo = async (_taskId?: string) => {
    const response: ServerResponse<Task> = await requestGetFetch(
      TASKS + `${_taskId ? _taskId : taskId}/`,
      {
        // user_id: userId,
        control_point_id: controlPointId,
      },
      accessToken
    );
    if (response?.data?.length > 0) {
      updateTaskInfo(response.data[0]);
    }
  };

  const loadMaterialInfo = async (materialId?: string): Promise<MaterialInfo> => {
    const response = await requestGetFetch(
      MATERIALS + `${materialId}/`,
      {},
      accessToken
    );

    const materialInfo = {
      name: null,
      thickness: null
    }

    if (response?.data?.length > 0) {
      materialInfo.name = response.data[0]?.roll_id
      materialInfo.thickness = response.data[0]?.thickness

      setCurrentMaterialName(materialInfo.name);
      setCurrentMaterialThickness(materialInfo.thickness);
    }
    return materialInfo
  };

  const updateTaskInfo = async (task: Task): Promise<{
    itemThickness: number | null,
    materiaName: string | null,
    materialThickness: number | null
  }> => {
    setCurrentTaskData(task);

    if (task?.execution_time != null) {
      const result = timestampToDateTime(task?.execution_time);
      let splitTime = result.split(':');

      if (splitTime.length == 2) {
        splitTime.unshift('00');
      }

      if (splitTime.length > 0) {
        selectedRoomRefHour.current = parseInt(splitTime[0]);
        selectedRoomRefMinute.current = parseInt(splitTime[1]);
        selectedRoomRefSecond.current = parseInt(splitTime[2]);

        setHourTime(parseInt(splitTime[0]));
        setMinuteTime(parseInt(splitTime[1]));
        setSecondTime(parseInt(splitTime[2]));
      }
      if (task?.status !== 'Done') {
        timerStop(true);
        setPauseTimer(true);
      }
    }

    if (task.downtime?.length > 0) {
      setDownTimeDate(task.downtime[0]);
    }

    if (task?.operation?.material_operations?.length > 0) {
      let _sumMinTime = '00:00:00';
      let _sumMaxTime = '00:00:00';
      for (
        let i = 0;
        i < task?.operation?.material_operations?.length;
        i++
      ) {
        _sumMinTime = formatTime(
          timestrToSec(_sumMinTime) +
          timestrToSec(
            task?.operation?.material_operations[i]?.min_time
          )
        );

        _sumMaxTime = formatTime(
          timestrToSec(_sumMaxTime) +
          timestrToSec(
            task?.operation?.material_operations[i]?.max_time
          )
        );
      }
      setSumMaxTime(_sumMaxTime);
      setSumMinTime(_sumMinTime);

      const taskEndMaterialOperationRecord =
        task?.operation?.material_operations?.filter(
          (value: any, index: number) => value?.type_operation === 'stop'
        );

      if (taskEndMaterialOperationRecord.length > 0) {
        setCurrentTaskMaxQuantity(taskEndMaterialOperationRecord[0]?.quantity);
      }
    }

    let materialInfo: MaterialInfo;
    if (task?.materials[0]?.material_id) {
      materialInfo = await loadMaterialInfo(
        task?.materials[0]?.material_id?.toString()
      );
    } else {
      materialInfo = await loadMaterialInfo(getCurrentMaterialId());
    }

    let _itemThickness = null;
    if (task?.operation?.material_operations.length > 0 && !parentTaskId) {
      _itemThickness = task?.operation?.material_operations[0]?.item?.thickness
      setItemThickness(_itemThickness);
    }
    return {
      itemThickness: _itemThickness,
      materiaName: materialInfo?.name,
      materialThickness: materialInfo?.thickness
    }
  };

  /**
   * Основная функция отвечает за получени данных о production flow
   */
  const setUpComponentData = async () => {
    setLoader(true);

    const response: ServerResponse<Task> = await requestGetFetch(
      TASKS + `${taskId}/`,
      {
        control_point_id: controlPointId,
      },
      accessToken
    );

    if (response?.data?.length > 0) {
      const currentTask: Task = response.data[0]

      getUser();  

      const result = await updateTaskInfo(currentTask);

      if (result.itemThickness !== result.materialThickness && isTaskFirstStart && !parentTaskId) {
        setIsThicknessCheckModalOpen(true);
      } else if (typeRoute === 'start') {
        if (
          currentTask.downtime?.length == 0 ||
          currentTask.status === 'Done'
        ) {
          startTimer();
        } else {
          setContinueTimerByStart(true);
        }
      }

      if (returnFromChild) {
        chooseNewMaterial();
      }

      setWorkResults({
        materialOperationMatrixId: null,
        quantityDone: null,
      });
    }
    setLoader(false);
  };

  const handleStartTask = () => {
    setIsThicknessCheckModalOpen(false);
    setIsTaskFirstStart(false);

    startTimer();
  };

  useEffect(() => {
    if (continueTimerByStart) {
      continueTimer();
    }
  }, [continueTimerByStart]);

  const getUser = async () => {
    setLoader(true);
    const response = await requestGetFetch(
      USERS + userId + '/',
      {},
      accessToken
    );
    if (response?.data?.length > 0) {
      setUser(response?.data[0]);
    }

    //Проверка токена
    if (response?.error?.length > 0) {
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }

    setLoader(false);
  };

  /**
   * Эффект случает на изменение таск ид
   */

  useEffect(() => {
    setUpComponentData();
  }, [taskId, accessToken]);

  /**
   * Эффект случает на изменение выбранного записья
   */
  useEffect(() => {
    if (typeof currentTask != 'undefined') {
      if (currentTask?.current_time != null) {
        let splited = currentTask?.current_time.split(':');
        if (splited?.length > 0) {
          let parsedHour = parseInt(splited[0]);
          let parsedMinutes = parseInt(splited[1]);
          let parsedSecond = parseInt(splited[2]);
          selectedRoomRefSecond.current = parsedSecond;
          selectedRoomRefHour.current = parsedHour;
          selectedRoomRefMinute.current = parsedMinutes;
          setHourTime(parsedHour);
          setMinuteTime(parsedMinutes);
          setSecondTime(parsedSecond);
        }
      }
    }
  }, [currentTask]);

  const requestForStartTimer = async () => {
    setLoader(true);
    const response = await requestPost(
      TASKS + taskId + '/start-task/',
      {
        user_id: parseInt(userId),
      },
      {},
      accessToken
    );
    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);

      //Проверка токена
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }
    setLoader(false);
  };

  const useMaterialApiCall = async () => {
    if (!currentMaterialId) {
      console.log(
        'Error: no current material set for controlpoint id:' + controlPointId
      );
      return;
    }

    const response = await requestPost(
      MATERIALS + 'use-material/',
      {
        task_id: taskId,
        material_id: currentMaterialId,
        status: 'started',
      },
      {},
      accessToken
    );

    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);

      // Проверка токена
      if (
        response?.error[0] ===
        'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }
  };

  /**
   * Функция старт таймер
   */
  const startTimer = () => {
    if (!hasMirror) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMaterialApiCall();
      requestForStartTimer();
    }

    timerStop(false);
    clearInterval(intervalIDRef.current);
    intervalIDRef.current = null;

    timerStop(true);
    if (typeof secondTime != 'undefined') {
      intervalIDRef.current = setInterval(() => {
        if (selectedRoomRefSecond.current >= 60) {
          selectedRoomRefSecond.current = 0;
          setSecondTime(1);

          const _timeUpMinute: number = selectedRoomRefMinute?.current + 1;
          selectedRoomRefMinute.current = _timeUpMinute;
          setMinuteTime(_timeUpMinute);
        }

        if (selectedRoomRefMinute.current >= 60) {
          selectedRoomRefMinute.current = 0;
          setMinuteTime(1);

          const _timeUpHour: number = selectedRoomRefHour?.current + 1;
          selectedRoomRefHour.current = _timeUpHour;
          setHourTime(_timeUpHour);
        } else {
          const _selectedRoomRefSecond = selectedRoomRefSecond.current;
          const _timeUp: number = _selectedRoomRefSecond + 1;

          selectedRoomRefSecond.current = _timeUp;
          setSecondTime(_timeUp);
        }
      }, 1000);
    }
  };

  /**
   * Функция отвечает за обновление production flow сумму деталей и времени
   */
  const saveData = async () => {
    setLoader(true);
    let sum: any = 0;
    for (
      let i = 0;
      i < currentTask?.operation?.material_operations?.length;
      i++
    ) {
      sum =
        parseInt(sum) +
        parseInt(currentTask?.operation?.material_operations[i].quantity);
    }

    const response = await requestPost(
      TASKS + `${taskId}/end-task/`,
      {
        user_id: parseInt(userId),
      },
      {},
      accessToken
    );

    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);

      //Проверка токена
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }
    setLoader(false);
  };

  /**
   * Остановка таймера
   */
  const cancelTimer = async () => {
    timerStop(false);
    clearInterval(intervalIDRef.current);
    intervalIDRef.current = null;
    await saveData();
  };

  /**
   * Пауза таймера
   */
  const pauseTimer = () => {
    setModalPurpose(true);
  };

  const pauseTimeAfterModalDone = () => {
    setPauseTimer(true);
    clearInterval(intervalIDRef.current);
    intervalIDRef.current = null;
    // updateData();
  };

  const updateDownTime = async () => {
    if (downTimeDate != null && typeof downTimeDate != 'undefined') {
      const response = await requestPost(
        DOWNTIME + `${downTimeDate?.id}/end/`,
        {
          task_id: currentTask?.id,
          user_id: parseInt(userId),
        },
        {},
        accessToken
      );

      if (response?.error?.length > 0) {
        toast.warning(response?.error[0]);
        //Проверка токена
        if (
          response?.error[0] ==
          'detail: Given token not valid for any token type'
        ) {
          refreshToken();
        }

        return false;
      }
    }

    return true;
  };

  /**
   * Продолжить таймер
   */
  const continueTimer = async () => {
    const statusTimer = await updateDownTime();
    //Если статус задачи закрытый то просто закрыть
    if (statusTimer == false) {
      return;
    }

    setPauseTimer(false);

    selectedRoomRefSecond.current = secondTime;
    selectedRoomRefHour.current = hourTime;
    if (typeof secondTime != 'undefined') {
      intervalIDRef.current = setInterval(() => {
        setPauseTimer(false);
        if (selectedRoomRefSecond.current >= 60) {
          selectedRoomRefSecond.current = 0;
          setSecondTime(1);

          const _timeUpMinute: number = selectedRoomRefMinute?.current + 1;
          selectedRoomRefMinute.current = _timeUpMinute;
          setMinuteTime(_timeUpMinute);
        }

        if (selectedRoomRefMinute.current >= 60) {
          selectedRoomRefMinute.current = 0;
          setMinuteTime(1);

          const _timeUpHour: number = selectedRoomRefHour?.current + 1;
          selectedRoomRefHour.current = _timeUpHour;
          setHourTime(_timeUpHour);
        } else {
          const _selectedRoomRefSecond = selectedRoomRefSecond.current;
          const _timeUp: number = _selectedRoomRefSecond + 1;

          selectedRoomRefSecond.current = _timeUp;
          setSecondTime(_timeUp);
        }
      }, 1000);
    }
  };

  const [newRollModalShow, setNewRollModalShow] = useState(false);
  const [newRollId, setNewRollId] = useState<any>(null);

  const [nextRollSetupParams, setNextRollSetupParams] =
    useState<RollSetupParams>({
      taskId: taskId,
      status: 'started',
    });

  /**
   * Отметить уровень использования рулона и перейти к следующему действию полученному от апи
   */
  const markRollUsageAndGetNextStep = async (
    rollStatus: string,
    checkResult = true
  ) => {
    setLoader(true);
    if (checkResult && workResults.quantityDone === null) {
      toast.warning('Сохраните промежуточный результат или введите 0, чтобы подтвердить переход');
      setLoader(false);
      throw new Error('Result is not saved before action');
    }

    const currentMaterialId = getCurrentMaterialId();

    if (!currentMaterialId) {
      console.log(
        'Error: no current material set for controlpoint id:' + controlPointId
      );
    }

    const response = await requestPost(
      MATERIALS + 'use-material/',
      {
        task_id: taskId,
        material_id: currentMaterialId,
        status: rollStatus,
      },
      {},
      accessToken
    );
    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);

      //Проверка токена
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }

    // откатить на дефолт текущий инкремент в работе для новго расчета дальше
    setWorkResults({
      materialOperationMatrixId: null,
      quantityDone: null,
    });

    /* если ответ не пустой - остаток текущего рулона, которого не
      хватит для продолжения текущей задачи, можно использовать в другой задаче. Нужно:
      1) поставить текущую задачу на паузу с причиной "докатка"
      2) перейти к выполнению задачи id которой пришел в ответе
    */
    setLoader(false);
    if (response?.data?.length > 0) {
      return { nextStep: 'MOVE_TO_TASK', taskId: response?.data[0]?.id };
    } else {
      return { nextStep: 'START_FLAT_SHEET' };
    }
  };

  const chooseNewMaterial = () => {
    const newNextRollSetupParams: RollSetupParams = {
      taskId: taskId,
      status: 'started',
    };
    setNextRollSetupParams(newNextRollSetupParams);
    setNewRollModalShow(true);
  };

  /**
   * Завершить рулон
   */
  const finishRoll = async () => {
    try {
      const result = await markRollUsageAndGetNextStep('used');
      if (result?.nextStep === 'START_FLAT_SHEET') {
        setTypeRoute('start');
        startFlatSheetProduction();
      } else if (result?.nextStep === 'MOVE_TO_TASK') {
        // TODO pause current task
        setTypeRoute('start');
        navigate(`/task-details/${result?.taskId}?parentTaskId=${taskId}`); // перейти в докат
      }
    } catch (e) {
      console.log(e);
    }
  };

  const changeRoll = async () => {
    // TODO pause current task

    setLoader(true);

    const currentMaterialId = getCurrentMaterialId();

    if (!currentMaterialId) {
      console.log(
        'Error: no current material set for controlpoint id:' + controlPointId
      );
    }

    const response = await requestPost(
      MATERIALS + currentMaterialId + '/idle-material/',
      {},
      {},
      accessToken
    );
    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);

      //Проверка токена
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    } else {
      chooseNewMaterial();
    }
    setLoader(false);
  };

  const isDesiredTaskQuantityProduced = (): boolean => {
    return (
      currentTaskMaxQuantity <=
      currentTask?.task_material_operation?.[0]?.quantity_done
    );
  };

  /**
   * Завершить выполнение задачи
   */
  const finishTask = async ({ notFullResultOk = false }) => {
    try {
      let nextNavigate: any;

      if (!notFullResultOk && workResults.quantityDone === null) {
        toast.warning('Предварительно необходимо сохранить текущий результат');
        throw new Error('Result is not saved before action');
      }

      if (!parentTaskId) {
        const result = await markRollUsageAndGetNextStep(
          'in_progress',
          !notFullResultOk
        );

        if (result?.nextStep === 'MOVE_TO_TASK') {
          setTypeRoute('start');
          nextNavigate = `/task-details/${result?.taskId}`;
        }
      } else {
        // TODO call setTypeRoute('continue'); когда добавим постановку задачи на паузу перед переходом к докату
        nextNavigate = `/task-details/${parentTaskId}?returnFromChild=true`;
      }

      await cancelTimer();

      navigate(nextNavigate || -1);
    } catch (e) {
      console.log(e);
    }
  };

  const startFlatSheetProduction = async () => {
    // TODO ставить текущую задачу на паузу

    const response = await requestPost(
      TASKS + `create-task-flat-sheet/`,
      {
        control_point_id: controlPointId,
        material_id: currentMaterialId,
      },
      {},
      accessToken
    );
    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);

      //Проверка токена
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }

    if (response?.data[0]?.id) {
      setTypeRoute('start');
      navigate(
        `/task-details/${response?.data[0]?.id}?parentTaskId=${parentTaskId || taskId}&flatSheet=true`
      ); // перейти в докат
    } else {
      toast('Ошибка при старте производства гладкого листа');
    }
  };

  const resetTask = async () => {
    const response = await requestPost(
      TASKS + `${taskId}/reset-task/`,
      {},
      {},
      accessToken
    );
    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);

      //Проверка токена
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }
  };

  const backToTasksList = async () => {
    const producedItems = currentTask?.task_material_operation?.filter(
      (val: any) => val.quantity_done > 0
    );

    if (producedItems.length === 0) {
      await resetTask();
    }

    navigate(`/task`);
  };

  return (
    <div className="w-full flex flex-col h-full mt-[59px] ">
      {modalSaveResultShow ? (
        <ModalSaveResult
          selectedData={currentTask}
          modalSaveResultShow={modalSaveResultShow}
          setModalSaveResultShow={setModalSaveResultShow}
          userId={userId}
          resultCallback={(savedWorkResults: any) => {
            setWorkResults(savedWorkResults);
          }}
          loadData={reloadTaskInfo}
          controlMaxQuantity={!(isFlatSheetProduction || parentTaskId)}
          maxTaskQuantity={currentTaskMaxQuantity}
        />
      ) : null}

      {modalStopPurpose ? (
        <ModalStopPurpose
          selectedData={currentTask}
          modalStopPurpose={modalStopPurpose}
          setModalPurpose={setModalPurpose}
          downTimeDate={downTimeDate}
          setDownTimeDate={setDownTimeDate}
          pauseTimeAfterModalDone={pauseTimeAfterModalDone}
          userId={userId}
        />
      ) : null}

      {newRollModalShow ? (
        <>
          <NewRollModal
            newRollModalShow={newRollModalShow}
            setNewRollModalShow={setNewRollModalShow}
            rollSubmitParams={nextRollSetupParams}
            currentTaskId={taskId}
            onSubmit={async (selectedRollId: string) => {
              setNewRollId(selectedRollId);
              setNewRollModalShow(false);
              if (parentTaskId) {
                // TODO call setTypeRoute('continue'); когда добавим постановку задачи на паузу перед переходом к докату
                navigate(`/task-details/${parentTaskId}`); // no returnFromChild=true since we don't want to choose new roll again
                await reloadTaskInfo(parentTaskId)
              }
            }}
            setNewRollSubmitResult={() => { }}
            loadDataTaskData={reloadTaskInfo}
            currentItemThickness={itemThickness}
          />
        </>
      ) : null}

      <Modal
        isOpen={isDefectModalOpen}
        onClose={() => setIsDefectModalOpen(false)}
        children={
          <DefectWindow
            taskId={taskId || ''}
            materialId={currentMaterialId}
            onSubmit={(isNewMaterialNeeded: boolean) => {
              if (isNewMaterialNeeded) {
                chooseNewMaterial();
              }
            }}
            setDefectWindowModalShow={(value: boolean) => {
              setIsDefectModalOpen(value);
            }}
          />
        }
      />

      <Modal
        isOpen={isTaskFinishConfirmModalOpen}
        onClose={() => setIsTaskFinishConfirmModalOpen(false)}
        children={
          <div className="text-center">
            <div className="text-[32px] text-center">
              Вы уверены что хотите завершить задачу?
              <br /> Все листы задачи будут считаться произведёнными
            </div>

            <div className="flex w-full gap-[8px] mt-[32px] justify-center">
              <Button
                text="Нет"
                color="secondary"
                onClick={() => {
                  setIsTaskFinishConfirmModalOpen(false);
                }}
              />

              <Button
                text="Да"
                onClick={() => {
                  finishTask({ notFullResultOk: true }).then(() =>
                    setIsTaskFinishConfirmModalOpen(false)
                  );
                }}
              />
            </div>
          </div>
        }
      />

      <Modal
        isOpen={isThicknessCheckModalOpen}
        onClose={() => setIsThicknessCheckModalOpen(false)}
        children={
          <div className="text-center">
            <div className="text-[32px] text-center">
              Толщина рулона и листа не совпадают. Продолжить?
            </div>

            <div className="flex w-full gap-[8px] mt-[32px] justify-center">
              <Button
                text="Сменить рулон"
                color="secondary"
                onClick={() => {
                  chooseNewMaterial();
                  setIsThicknessCheckModalOpen(false);
                }}
              />

              <Button text="Продолжить" onClick={handleStartTask} />
            </div>
          </div>
        }
      />

      <div className="w-full flex justify-between   gap-[10px]  text-[20px] font-nunito font-medium bg-white h-full">
        <div className="flex flex-col p-[0_15px_0_52px]">
          <div
            onClick={() => {
              if(isFlatSheetProduction || parentTaskId) {
                navigate(-1)
              } else {
                backToTasksList();
              }
            }}
            className="flex py-[18px] gap-[10px] cursor-pointer"
          >
            <svg
              width={18}
              height={18}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z" />
            </svg>
            <div className="font-nunito text-[14px] font-medium">
              {isFlatSheetProduction || parentTaskId ? 'Назад к предыдущей задаче': 'Назад к списку'}
            </div>
          </div>

          <div className="relative rounded-[8px] flex gap-[20px] ">
            <div className="w-full ">
              <div className="w-full flex flex-col gap-[24px]">
                <div className="w-full flex flex-col gap-[5px] justify-start items-start w-[646px]">
                  <h3 className="text-[24px] font-medium font-nunito  text-[#252628] ">
                    {currentTask?.operation?.name}
                  </h3>
                  <p className="font-nunito text-[16px] text-start">
                    <SafeHtmlComponent
                      html={parseAndBoldText(
                        currentTask?.operation?.description || ''
                      )}
                    />
                  </p>
                </div>

                {!hasMirror && (
                  <Button
                    text="Сообщить о браке"
                    color="secondary"
                    height="70px"
                    onClick={() => {
                      setIsDefectModalOpen(true);
                    }}
                  />
                )}

                <div className="">
                  {/*{activeMenu === 'instruction' ? (*/}
                  {/*  <TaskDetailsInstructions />*/}
                  {/*) : null}*/}

                  {/*{activeMenu === 'сharacteristics' ? (*/}
                  <TaskDetailsCharacteristics
                    selectedData={currentTask}
                    freeQuantity={isFlatSheetProduction}
                    setItemThickness={setItemThickness}
                  />
                  {/*) : null}*/}

                  {/*{activeMenu === 'necessary_materials' ? (*/}
                  {/*  <TaskDetailsNecessaryMaterials*/}
                  {/*    selectedData={selectedData}*/}
                  {/*  />*/}
                  {/*) : null}*/}

                  {/*{activeMenu === 'blueprints' ? (*/}
                  {/*  <TaskDetailsBlueprints />*/}
                  {/*) : null}*/}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Rigth side */}
        <div className="border-t-[1px] border-[#cfcfcf] flex flex-col justify-between h-[80vh] w-[480px] bg-[#F8F8F8]">
          <div className="flex flex-col gap-[30px] justify-between h-full">
            <div className="flex flex-col gap-[12px]">
              <div className="w-full flex flex-col gap-[8px] px-[31px] mt-[25px]">
                <p className="font-nunito text-[28px] font-medium">
                  Задача {currentTask?.id}
                </p>
                <div className="w-full flex flex-col gap-[8px]">
                  <div className="w-full flex flex-col gap-[5px] ">
                    <p className="font-nunito text-[16px] font-normal leading-[16px]">
                      Ответственный
                    </p>
                    <p className="font-nunito text-[16px] font-normal leading-[16px]">
                      <b>{user?.name}</b>
                    </p>
                  </div>
                  {/*<div className="w-full flex flex-col gap-[5px]">*/}
                  {/*  <p className="font-nunito text-[16px] font-normal leading-[16px]">*/}
                  {/*    Минимальное плановое время выполнения: <b>{sumMinTime}</b>*/}
                  {/*  </p>*/}
                  {/*  <p className="font-nunito text-[16px] font-normal leading-[16px]">*/}
                  {/*    Максимальное плановое время выполнения:{' '}*/}
                  {/*    <b>{sumMaxTime}</b>*/}
                  {/*  </p>*/}
                  {/*</div>*/}
                </div>
              </div>

              {!hasMirror && (
                <div className="w-full flex flex-col px-[31px] gap-[9px]">
                  <Button
                    text="Добавить выполненное количество"
                    color="secondary"
                    height="70px"
                    onClick={() => setModalSaveResultShow(true)}
                  />
                  <div className="flex flex-col mt-[10px] w-full gap-[8px]">
                    {timerStart && !parentTaskId && (
                      <Button
                        text="Начать докат"
                        onClick={() => finishRoll()}
                        height="30px"
                      />
                    )}
                    {timerStart && !parentTaskId && (
                      <Button
                        text="Поменять рулон"
                        onClick={() => changeRoll()}
                        height="30px"
                        color="secondary"
                      />
                    )}
                    {timerStart && parentTaskId && !isFlatSheetProduction && (
                      <Button
                        text="Произвести гладкий лист"
                        onClick={() => startFlatSheetProduction()}
                        height="30px"
                      />
                    )}
                  </div>

                  {currentMaterialName && (
                    <div>
                      <span className="text-[black] px-[5px] text-[24px]  text-start font-bold">
                        Используемый рулон:
                      </span>{' '}
                      <br />
                      <span>{currentMaterialName}</span>
                    </div>
                  )}
                </div>
              )}
            </div>

            {/*{timerStart ? (*/}
            {/*  <div className="py-[13px] w-full flex flex-col justify-center items-center  border">*/}
            {/*    <p className="font-nunito text-[18px]">*/}
            {/*      Время работы над задачей*/}
            {/*    </p>*/}
            {/*    <p className="font-nunito text-[28px]">*/}
            {/*      {hourTime < 10 ? '0' + hourTime : hourTime}:*/}
            {/*      {minuteTime < 10 ? '0' + minuteTime : minuteTime}:*/}
            {/*      {secondTime < 10 ? '0' + secondTime : secondTime}*/}
            {/*    </p>*/}
            {/*  </div>*/}
            {/*) : null}*/}
          </div>

          {!hasMirror && (
            <div className="w-full flex flex-col px">
              {/*{!timerStart ? (*/}
              {/*  <div className="py-[13px] w-full flex flex-col justify-center items-center bg-white border">*/}
              {/*    <p className="font-nunito text-[18px]">*/}
              {/*      Время работы над задачей*/}
              {/*    </p>*/}
              {/*    <p className="font-nunito text-[28px]">*/}
              {/*      {hourTime < 10 ? '0' + hourTime : hourTime}:*/}
              {/*      {minuteTime < 10 ? '0' + minuteTime : minuteTime}:*/}
              {/*      {secondTime < 10 ? '0' + secondTime : secondTime}*/}
              {/*    </p>*/}
              {/*  </div>*/}
              {/*) : null}*/}

              {!timerStart ? (
                <Button
                  text="Начать"
                  color="black"
                  noRadius
                  onClick={() => {
                    startTimer();
                  }}
                  height="30px"
                />
              ) : (
                <div style={{ display: 'flex' }}>
                  {!pauseTime ? (
                    <div
                      onClick={() => {
                        pauseTimer();
                      }}
                    >
                      <PauseIcon />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        continueTimer();
                      }}
                    >
                      <PlayIcon />
                    </div>
                  )}

                  <div
                    onClick={() => {
                      isTaskFinishConfirmModalOpen &&
                        finishTask({ notFullResultOk: true });

                      if (
                        !isFlatSheetProduction &&
                        !parentTaskId &&
                        !isDesiredTaskQuantityProduced()
                      ) {
                        setIsTaskFinishConfirmModalOpen(true);
                      } else {
                        finishTask({ notFullResultOk: false });
                      }
                    }}
                    style={{ width: '100%' }}
                  >
                    <Button
                      text={isFlatSheetProduction ? "Завершить рулон" : "Завершить"}
                      color="black"
                      height="122px"
                      noRadius
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
